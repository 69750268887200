<template>
  <AntCheckbox
    v-model:checked="value"
    :disabled="formState?.disabled || disabled"
    class="gum-checkbox"
  >
    <slot />
  </AntCheckbox>
</template>

<script setup lang="ts">
import AntCheckbox from 'ant-design-vue/es/checkbox'
import { computed } from 'vue'
import { useFormState } from './global-state'

const formState = useFormState()

const props = defineProps<{
  value: boolean | null | undefined
  disabled?: boolean
}>()
const emit = defineEmits<{
  (e: 'update:value', val: boolean): void
}>()

const value = computed({
  get() {
    return props.value || false
  },
  set(val) {
    emit('update:value', val)
  }
})
</script>

<style lang="less">
.gum-checkbox {
  .ant-checkbox-inner {
    border-radius: 4px;
  }
}
</style>
